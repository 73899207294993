@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

* {
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Selection text color change */
/* 
::-moz-selection {
  color: white;
  background: #F5437E;
}

::selection {
  color: white;
  background: #F5437E;
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html {
	font-size: 62.5%;
	/* here we are setting 1rem = 10px; by default 1rem = 16px but for simple calculation we are setting it to 10px. 62.5% of 16px = 10px */
	box-sizing: border-box;
}

::-webkit-scrollbar {
	width: 0.3125em;
	border-radius: 1.875em;
	height: 0.3125em !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff;
	width: 15px !important;
	height: 3px !important;
	border-radius: 1.875em;
}

/* Handle */
::-webkit-scrollbar-thumb {
	width: 0.3125em !important;
	background: #777777;
	border-radius: 1.25em;
}

input {
	border: 1px solid #E5E5E5;
	border-radius: 1rem;
	font-size: 1.6rem;
	line-height: 2.4rem;
	outline: none;
	padding: 1.4rem;
	color: #464646;
	background-color: #f7f7f7;
	width: 100%;
	cursor: pointer;
	box-sizing: border-box;
}


.profile .footer {
	display: none;
}

.profile .webHeader {
	display: none;
}
/* Font family global */
.font_Inter {
  font-family: "Inter", sans-serif !important;
}

.font_DM_Sans {
  font-family: "DM Sans", sans-serif !important;
}

.font_Inter_Tight {
  font-family: "Inter Tight", sans-serif !important;
}

/* INLINE___BLOCK */
.display_Inline_Block {
  display: inline-block;
}

/* flex properties */
.display_flex {
  display: flex;
}

.row_flex {
  display: flex;
  flex-direction: row;
}

.col_flex {
  display: flex;
  flex-direction: column;
}

.row_flex_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.col_flex_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.align_center {
  display: flex;
  align-items: center;
}

.justify_space_between {
  justify-content: space-between;
}

.justify_space_around {
  justify-content: space-around;
}

.justify_space_evenly {
  justify-content: space-evenly;
}

.justify_center {
  justify-content: center;
}

.justify_start {
  justify-content: flex-start;
}

.justify_none {
  justify-content: none;
}

.justify_end {
  justify-content: flex-end;
}

.align_items_center {
  align-items: center;
}

.align_start {
  align-items: flex-start;
}

.align_end {
  align-items: flex-end;
}

.flex_wrap {
  flex-wrap: wrap;
}

/* ------GAP----- */
.gap_2 {
  gap: 0.2rem;
}

.gap_4 {
  gap: 0.4rem;
}

.gap_5 {
  gap: 0.5rem;
}

.gap_6 {
  gap: 0.6rem;
}

.gap_8 {
  gap: 0.8rem;
}

.gap_10 {
  gap: 1rem;
}

.gap_15 {
  gap: 1.5rem;
}

.gap_20 {
  gap: 2rem;
}

.gap_30 {
  gap: 3rem;
}

/* -----BORDER-RADIUS------ */
.borderRadius_6 {
  border-radius: 0.6rem;
}

.borderRadius_10 {
  border-radius: 1rem;
}

.border_none {
  border: none !important;
}

/* font weight */
.font_400 {
  font-weight: 400;
}

.font_500 {
  font-weight: 500;
}

.font_600 {
  font-weight: 600;
}

.font_700 {
  font-weight: 700;
}

.font_bold {
  font-weight: bold;
}

.font_normal {
  font-weight: normal;
}

/* cursor properties */
.cursor_pointer {
  cursor: pointer;
}

.cursor_not_allowed {
  cursor: not-allowed;
}

.cursor_default {
  cursor: default;
}

/* position properties */
.pos_relative {
  position: relative;
}

.pos_absolute {
  position: absolute;
}

.rignt_0 {
  right: 0;
}

.left_0 {
  left: 0;
}

.top_0 {
  top: 0;
}

.bottom_0 {
  bottom: 0;
}

.bottom_30 {
  bottom: 3rem;
}

.zIndex_1 {
  z-index: 1;
}

/* text align properties */
.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

/* font-size */
.fontSize_10 {
  font-size: 1rem;
}


.fontSize_12 {
  font-size: 1.2rem;
}

.fontSize_14 {
  font-size: 1.4rem;
}

.fontSize_15 {
  font-size: 1.5rem;
}

.fontSize_16 {
  font-size: 1.6rem;
}

.fontSize_18 {
  font-size: 1.8rem;
}

.fontSize_20 {
  font-size: 2rem;
}

.fontSize_22 {
  font-size: 2.2rem;
}

.fontSize_24 {
  font-size: 2.4rem;
}

.fontSize_28 {
  font-size: 2.8rem;
}

.fontSize_30 {
  font-size: 3rem;
}

.fontSize_36 {
  font-size: 3.6rem;
}

.fontSize_40 {
  font-size: 4rem;
}

/* -----LINEHEIGHT------- */
.lineHeight_24 {
  line-height: 2.4rem;
}

.lineHeight_20 {
  line-height: 2rem;
}

/* height and width */
.h_100 {
  height: 100%;
}

.h_100vh {
  height: 100dvh;
}

.w_100 {
  width: 100%;
}

.w_50 {
  width: 50%;
}

.w_75 {
  width: 75%;
}

.w_25 {
  width: 25%;
}

.w_auto {
  width: auto !important;
}

.w_fit_content {
  width: fit-content !important;
}

.w_max_content {
  width: max-content;
}

/* --- colors --- */
.primary_color {
  color: #E11F60;
  /* color: #2551CB; */
}

.color_black {
  color: black;
}

.color_white {
  color: white;
}

.color_initial {
  color: initial;
}

/* ---BACKGROUND_COLOR----- */
.bg_f1f1f1 {
  background-color: #f1f1f1;
}

.bg_white {
  background-color: white;
}

.bg_transparent {
  background-color: transparent;
}

/* ---MARGINS--- */
/* ------ auto ------ */
.margin_l_auto {
  margin-left: auto;
}

.margin_r_auto {
  margin-right: auto;
}

.margin_auto {
  margin: auto;
}

/* ------ 0 ------ */
.margin_0 {
  margin: 0;
}

.margin_t_0 {
  margin-top: 0rem;
}

.margin_b_0 {
  margin-bottom: 0rem;
}

.margin_l_0 {
  margin-left: 0rem;
}

.margin_r_0 {
  margin-right: 0rem;
}

.margin_lr_0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.margin_tb_0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.margin_tb_16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

/* ------2------ */
.margin_lr_2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

/* ------ 4 ------ */
.margin_r_4 {
  margin-right: 0.4rem;
}

.margin_l_4 {
  margin-left: 0.4rem;
}

.margin_lr_4 {
  margin: 0 0.4rem;
}

/* ------ 8 ------ */
.margin_8 {
  margin: 8px;
}

.margin_t_8 {
  margin-top: 0.8rem;
}

.margin_b_8 {
  margin-bottom: 0.8rem;
}

.margin_l_8 {
  margin-left: 0.8rem;
}

.margin_r_8 {
  margin-right: 0.8rem;
}

.margin_lr_8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.margin_tb_8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

/* ------ 10 ------ */
.margin_t_10 {
  margin-top: 1rem;
}

.margin_b_10 {
  margin-bottom: 1rem;
}

.margin_l_10 {
  margin-left: 1rem;
}

.margin_r_10 {
  margin-right: 1rem;
}

.margin_lr_10 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.margin_tb_10 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* ----------12---------- */
.margin_tb_12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.margin_t_12 {
  margin-top: 1.2rem;
}

.margin_t_15 {
  margin-top: 1.5rem;
}

.margin_t_5 {
  margin-top: 0.5rem;
}

.margin_b_12 {
  margin-bottom: 1.2rem;
}

.margin_b_20 {
  margin-bottom: 2rem;
}

/* ------16----- */
.margin_tb_16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

/* ------ 18 ------ */
.margin_t_18 {
  margin-top: 1.8rem;
}

/* ------ 20 ------ */
.margin_t_20 {
  margin-top: 2rem;
}

.margin_tb_20 {
  margin: 2rem 0;
}

.margin_tb_20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.margin_lr_20 {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* ---------------------- */
.margin_t_6 {
  margin-top: 0.6rem;
}

.margin_tb_24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.margin_t_24 {
  margin-top: 2.4rem;
}

.margin_b_24 {
  margin-bottom: 2.4rem;
}

.margin_b_28 {
  margin-bottom: 2.8rem;
}

.margin_t_44 {
  margin-top: 4.4rem;
}

.margin_t_32 {
  margin-top: 3.2rem;
}

.margin_t_64 {
  margin-top: 6.4rem;
}

.margin_t_-4 {
  margin-top: -0.4rem;
}

.margin_b_-4 {
  margin-bottom: -0.4rem;
}

.margin_b_60 {
  margin-bottom: 6rem;
}

/* PADDING */
.padding_auto {
  padding: auto;
}

/* ------ 4 ------ */
.padding_4 {
  padding: 0.4rem;
}

.padding_16 {
  padding: 1.6rem;
}

.padding_t_4 {
  padding-top: 0.4rem;
}

.padding_b_4 {
  padding-bottom: 0.4rem;
}

.padding_b_16 {
  padding-bottom: 1.6rem;
}

.padding_b_24 {
  padding-bottom: 2.4rem;
}

.padding_l_4 {
  padding-left: 0.4rem;
}

.padding_r_4 {
  padding-right: 0.4rem;
}

.padding_lr_4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.padding_tb_4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/* ------ 8 ------ */
.padding_8 {
  padding: 0.8rem;
}

.padding_t_8 {
  padding-top: 0.8rem;
}

.padding_b_8 {
  padding-bottom: 0.8rem;
}

.padding_l_8 {
  padding-left: 0.8rem;
}

.padding_r_8 {
  padding-right: 0.8rem;
}

.padding_lr_8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.padding_tb_8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

/* ------ 10 ------ */
.padding_t_10 {
  padding-top: 1rem;
}

.padding_t_32 {
  padding-top: 3.2rem;
}

.padding_t_64 {
  padding-top: 6.4rem;
}

.padding_b_10 {
  padding-bottom: 1rem;
}

.padding_l_0 {
  padding-left: 0rem;
}

.padding_l_10 {
  padding-left: 1rem;
}

.padding_l_20 {
  padding-left: 2rem;
}

.padding_r_10 {
  padding-right: 1rem;
}

.padding_lr_10 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding_tb_10 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* -----12------ */
.padding_t_12 {
  padding-top: 1.2rem;
}

/* -----14------ */
.padding_tb_14 {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

/* -----16------ */
.padding_tb_16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

/* --------20--------- */
.padding_20 {
  padding: 2rem;
}

.padding_lr_16 {
  padding: 0 1.6rem;
}

.padding_lr_20 {
  padding: 0 2rem;
}

.padding_24 {
  padding: 2.4rem;
}

.padding_b_32 {
  padding-top: 3.2rem;
}

.padding_tb_20 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padding_tb_40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.padding_b_100 {
  padding-bottom: 10rem;
}

.padding_tb_36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
}

.padding_tb_40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/* miscellaneous */
.shadow-1 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
}

.break_all {
  word-break: break-all;
}

/* UnderLine */
.underline {
  text-decoration: underline;
}

.underline_none {
  text-decoration-line: none;
}

/* container width fixes */
.container {
  width: 100%;
}

.no-scroll {
  overflow: hidden;
}

.overflow_hidden {
  overflow: hidden !important;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1024px;
  }
}